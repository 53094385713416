import Vue from "vue";
import Vuex from "vuex";
import config from "./config";
import * as $api from "./api";

Vue.use(Vuex);

const storageInstance = window.localStorage;

function updateHtmlLang(lang) {
  // update html page tag
  const html = document.documentElement;
  html.setAttribute("lang", lang);
}

function getLocale() {
  try {
    const currentLocale = storageInstance.getItem("locale");
    if (!currentLocale) {
      const localeComplete = navigator.language || navigator.userLanguage;
      let language = localeComplete.substring(0, 2).toLowerCase();
      if (language !== "ar" && language !== "en" && language !== "it") {
        language = "en"; // fallback to english
      }
      console.log("language set to", language);
      storageInstance.setItem("locale", language);
      updateHtmlLang(language);
      return language;
    } else {
      console.log("language cached", currentLocale);
      updateHtmlLang(currentLocale);
      return currentLocale;
    }
  } catch (err) {
    console.log("failed to set language, fallback to en");
    return "en";
  }
}

function getPaddlePrice(planId) {
  return new Promise((resolve, reject) => {
    try {
      window.Paddle.Product.Prices(planId, (prices) => {
        console.log(prices);
        return resolve(prices.recurring.price.gross);
      });
    } catch (err) {
      console.error(err);
      return reject(err);
    }
  });
}

const store = new Vuex.Store({
  state: {
    config,
    locale: getLocale(),
    plans: [{}, {}, {}, {}],
    userPlan: {
      user: {},
      plan: {},
    },
    user: {
      emailVerified: false,
    }, // firebase data
    isLoading: true,
    userProfile: null,
    snackbar: {
      content: "",
      color: "",
    },
    userDomains: [],
    availableDomains: [],
    availableDomainsStrippedDown: [],
  },
  // note. 2022 edition is used to force new behviour for
  // registered users on 2022
  getters: {
    monthlyClicksCounter(state) {
      return state.userPlan.user.monthly_clicks_count;
    },
    totalLinksCounter(state) {
      return state.userPlan.user.links_count;
    },
    getUserName(state) {
      return state.user.displayName;
    },
    getUserEmail(state) {
      return state.user.email;
    },
    isEmailVerified(state) {
      return state.user.emailVerified;
    },
    /**
     * @returns string e.g. Sun, 03 May 2020 17:48:48 GMT
     */
    getUserSignupDate(state) {
      return state.user.metadata.creationTime;
    },
    isEmailVerified2022(state) {
      // after signup for few seconds is empty
      if (typeof state.user.metadata === "undefined") {
        return true;
      }
      const userCreationDate = new Date(state.user.metadata.creationTime);
      const now = new Date("2021-12-31T16:31:11.969Z");

      // for old accounts (< 2022) consider verified
      if (userCreationDate.getTime() < now.getTime()) {
        return true;
      }

      return state.user.emailVerified;
    },
    isAuthenticated(state) {
      if (state.userProfile === null) return false;
      return true;
    },
    canUpgrade(state) {
      return (planName) => {
        let positionOfPlan = -1;
        let positionOfUserPlan = -1;
        for (const [index, plan] of state.plans.entries()) {
          if (plan.name == planName) {
            positionOfPlan = index;
          }
          if (plan.name == state.userPlan.plan.name) {
            positionOfUserPlan = index;
          }
          if (positionOfUserPlan !== -1 && positionOfPlan !== -1) {
            break;
          }
        }
        return positionOfUserPlan < positionOfPlan;
      };
    },
    /**
     * plan of user
     */
    userPlanObject(state) {
      return state.userPlan.plan;
    },
    userPlanInfo(state) {
      console.log(state.plans, JSON.stringify(state.userPlan));
      const match = state.plans.filter(
        (plan) => plan.id === state.userPlan.plan.id
      );
      console.log(match);
      if (!match || match.length === 0) return {};
      return match[0];
    },
  },
  mutations: {
    changeLanguage(state, newLang) {
      /*
      storageInstance.setItem("locale", newLang);
      state.locale = newLang;

      this.$app.$vuetify.rtl = newLang === "ar";
      this.$app.$i18n.locale = newLang;

      updateHtmlLang(newLang);*/
    },
    setPlans(state, plans) {
      state.plans = plans;
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setUserPlan(state, userPlan) {
      state.userPlan = userPlan;
    },
    setAvailableDomains(state, domainList) {
      const userOnlyDomains = domainList.filter((d) => d.ownerId !== null);
      state.userDomains = userOnlyDomains;
      state.availableDomains = domainList;
      state.availableDomainsStrippedDown = domainList.map((d) => d.domain);
    },
    setUser(state, user) {
      state.user = user;
    },
    showSnackbarMessage(state, payload) {
      state.snackbar.content = payload.content;
      state.snackbar.color = payload.color;
    },
  },
  actions: {
    async getPlans({ commit }) {
      const plans = await $api.getPlans();
      for (const [index, plan] of plans.entries()) {
        if (!plan.activePaddleId) {
          continue;
        }
        console.log("getting plan", plan.activePaddleId);
        plans[index].price = await getPaddlePrice(plan.activePaddleId);
      }
      commit("setPlans", plans);
    },
    async getUserPlan({ commit }) {
      const userWithPlan = await $api.getUserPlan();
      commit("setUserPlan", userWithPlan);
    },
    async getAvailableDomains({ commit }) {
      const domainList = await $api.getDomains(true);
      commit("setAvailableDomains", domainList.result);
    },
  },
});

export default store;
