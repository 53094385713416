var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("your_plan"))+":")]),_c('span',{staticClass:"headline black--text font-weight-medium text-uppercase ml-1"},[_vm._v(_vm._s(this.userPlan.plan.name))]),(_vm.userPlan.user.paddle_cancellation_effective_date)?_c('span',[_vm._v(" - Plan set to be cancelled automatically on: "+_vm._s(_vm.getDayDate(_vm.userPlan.user.paddle_cancellation_effective_date))+" "),_c('button-of-plan',{staticClass:"d-inline-block",attrs:{"planName":_vm.userPlan.plan.name,"smallBtn":""}})],1):(_vm.userPlan.user.padle_next_bill_date)?_c('span',[_vm._v(" - Next payment due: "+_vm._s(_vm.getDayDate(_vm.userPlan.user.padle_next_bill_date)))]):_vm._e(),_c('br'),(
        this.userPlan.plan.name !== 'free' &&
        this.paddleUser &&
        this.paddleUser.payment_information
      )?_c('p',[_vm._v(" Payment method: "+_vm._s(_vm.capitalize(this.paddleUser.payment_information.payment_method))+" "),(
          'last_four_digits' in this.paddleUser.payment_information &&
          typeof this.paddleUser.payment_information.last_four_digits ===
          'string' &&
          this.paddleUser.payment_information.last_four_digits
        )?_c('span',[_vm._v(" "+_vm._s(this.paddleUser.payment_information.last_four_digits)+" ")]):_vm._e(),(
          'card_type' in this.paddleUser.payment_information &&
          typeof this.paddleUser.payment_information.card_type ===
          'string' &&
          this.paddleUser.payment_information.card_type
        )?_c('span',[_vm._v(" "+_vm._s(("(" + (this.paddleUser.payment_information.card_type) + ")"))+" ")]):_vm._e()]):_vm._e(),(
        this.userPlan.plan.name !== 'free' &&
        !this.userPlan.user.paddle_cancellation_effective_date
      )?_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","outlined":"","loading":_vm.openingPaddleUpdateUrl},on:{"click":_vm.openPaddleUpdateUrl}},[_vm._v("Update payment method")]):_vm._e(),_c('br')],1)],1),_c('div',{staticClass:"headline mb-2 mt-5"},[_vm._v(_vm._s(_vm.$t("available_plans")))]),_c('p',{staticClass:"grey--text"},[_vm._v(" Checkout the list of detailed features "),_c('a',{attrs:{"href":"https://1link.io/pricing#features","target":"_blank"}},[_vm._v("here")])]),_c('v-row',[_c('v-col',[_c('v-layout',[_c('v-flex',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-center title text-uppercase blue--text darken-3--text"},[_vm._v(" Free ")]),_c('th',{staticClass:"text-center title text-uppercase blue--text darken-3--text"},[_vm._v(" Startup ")]),_c('th',{staticClass:"text-center title text-uppercase blue--text darken-3--text"},[_vm._v(" Pro ")]),_c('th',{staticClass:"text-center title text-uppercase blue--text darken-3--text"},[_vm._v(" Enterprise ")])])]),_c('tbody',{staticClass:"text-center"},[_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"font-weight-medium"},[_vm._v("Clicks per month limit")]),_c('td',[_vm._v("100")]),_c('td',[_vm._v("5'000")]),_c('td',[_vm._v("50'000")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-all-inclusive")])]}}])},[_c('span',[_vm._v("No limits")])])],1)]),_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"font-weight-medium"},[_vm._v("Total Links")]),_c('td',[_vm._v("5")]),_c('td',[_vm._v("100")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-all-inclusive")])]}}])},[_c('span',[_vm._v("No limits")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-all-inclusive")])]}}])},[_c('span',[_vm._v("No limits")])])],1)]),_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"font-weight-medium"},[_vm._v("Customize landing page")]),_c('td',[_c('v-icon',[_vm._v("mdi-close")])],1),_c('td',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1),_c('td',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1),_c('td',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1)]),_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"font-weight-medium"},[_vm._v("Custom domains")]),_c('td',[_c('v-icon',[_vm._v("mdi-close")])],1),_c('td',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1),_c('td',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1),_c('td',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1)]),_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"font-weight-medium"},[_vm._v("URL shortener (custom domains)")]),_c('td',[_c('v-icon',[_vm._v("mdi-close")])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-check-circle")])]}}])},[_c('span',[_vm._v(" If you have a custom domain you can customize how it works and "),_c('br'),_vm._v("add paths to it, e.g. mydomain.com/get -> link-to.app/demo ")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-check-circle")])]}}])},[_c('span',[_vm._v(" If you have a custom domain you can customize how it works and "),_c('br'),_vm._v("add paths to it, e.g. mydomain.com/get -> link-to.app/demo ")])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-check-circle")])]}}])},[_c('span',[_vm._v(" If you have a custom domain you can customize how it works and "),_c('br'),_vm._v("add paths to it, e.g. mydomain.com/get -> link-to.app/demo ")])])],1)]),_c('tr',{staticClass:"text-center"},[_c('td',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("price"))+" (monthly) ")]),_c('td',{staticClass:"font-weight-medium"},[_vm._v("0")]),_c('td',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.plans[1].price))]),_c('td',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.plans[2].price))]),_c('td',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.plans[3].price))])]),_c('tr',{staticClass:"text-center"},[_c('td'),_c('td',[_c('button-of-plan',{attrs:{"planName":_vm.plans[0].name}})],1),_c('td',[_c('button-of-plan',{attrs:{"planName":_vm.plans[1].name}})],1),_c('td',[_c('button-of-plan',{attrs:{"planName":_vm.plans[2].name}})],1),_c('td',[_c('button-of-plan',{attrs:{"planName":_vm.plans[3].name}})],1)])])]},proxy:true}])})],1)],1)],1)],1),_c('div',{staticClass:"headline mb-2 mt-5"},[_vm._v("Receipts")]),_c('v-row',[_c('v-col',[_c('v-layout',[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"height":"300","disable-pagination":"","disable-filtering":"","headers":_vm.transactionsHeaders,"items":_vm.transactions,"loading":_vm.transactionsLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(item.currency)+" ")]}},{key:"item.receipt_url",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","href":item.receipt_url,"target":"_blank"}},[_vm._v("open")])]}}])}),_c('download-csv',{staticClass:"my-3",attrs:{"data":_vm.transactions,"name":"receipts_1link.csv"}},[_c('v-btn',{attrs:{"text":""}},[_vm._v("CSV Export")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }