<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="title">{{ $t("your_plan") }}:</span>
        <span class="headline black--text font-weight-medium text-uppercase ml-1">{{ this.userPlan.plan.name }}</span>
        <span v-if="userPlan.user.paddle_cancellation_effective_date">
          - Plan set to be cancelled automatically on:
          {{ getDayDate(userPlan.user.paddle_cancellation_effective_date) }}
          <button-of-plan :planName="userPlan.plan.name" smallBtn class="d-inline-block" /></span>
        <span v-else-if="userPlan.user.padle_next_bill_date">
          - Next payment due:
          {{ getDayDate(userPlan.user.padle_next_bill_date) }}</span>
        <br />
        <!-- TODO add edit billing info btn -->
        <p v-if="
          this.userPlan.plan.name !== 'free' &&
          this.paddleUser &&
          this.paddleUser.payment_information
        ">
          Payment method:
          {{ capitalize(this.paddleUser.payment_information.payment_method) }}

          <span v-if="
            'last_four_digits' in this.paddleUser.payment_information &&
            typeof this.paddleUser.payment_information.last_four_digits ===
            'string' &&
            this.paddleUser.payment_information.last_four_digits
          ">
            {{ this.paddleUser.payment_information.last_four_digits }}
          </span>
          <span v-if="
            'card_type' in this.paddleUser.payment_information &&
            typeof this.paddleUser.payment_information.card_type ===
            'string' &&
            this.paddleUser.payment_information.card_type
          ">
            {{ `(${this.paddleUser.payment_information.card_type})` }}
          </span>
        </p>
        <v-btn small outlined class="mt-2" :loading="openingPaddleUpdateUrl" @click="openPaddleUpdateUrl" v-if="
          this.userPlan.plan.name !== 'free' &&
          !this.userPlan.user.paddle_cancellation_effective_date
        ">Update payment method</v-btn>
        <br />
      </v-col>
    </v-row>
    <!-- Add cancel, edit billing, plan info -->
    <div class="headline mb-2 mt-5">{{ $t("available_plans") }}</div>
    <p class="grey--text">
      Checkout the list of detailed features <a href="https://1link.io/pricing#features" target="_blank">here</a>
    </p>
    <v-row>
      <v-col>
        <v-layout>
          <v-flex>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center title text-uppercase blue--text darken-3--text">
                      Free
                    </th>
                    <th class="text-center title text-uppercase blue--text darken-3--text">
                      Startup
                    </th>
                    <th class="text-center title text-uppercase blue--text darken-3--text">
                      Pro
                    </th>
                    <th class="text-center title text-uppercase blue--text darken-3--text">
                      Enterprise
                    </th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr class="text-center">
                    <td class="font-weight-medium">Clicks per month limit</td>
                    <td>100</td>
                    <td>5'000</td>
                    <td>50'000</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon color="green" v-on="on">mdi-all-inclusive</v-icon>
                        </template>
                        <span>No limits</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td class="font-weight-medium">Total Links</td>
                    <td>5</td>
                    <td>100</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon color="green" v-on="on">mdi-all-inclusive</v-icon>
                        </template>
                        <span>No limits</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon color="green" v-on="on">mdi-all-inclusive</v-icon>
                        </template>
                        <span>No limits</span>
                      </v-tooltip>
                    </td>
                  </tr>

                  <tr class="text-center">
                    <td class="font-weight-medium">Customize landing page</td>
                    <td>
                      <v-icon>mdi-close</v-icon>
                    </td>
                    <td>
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </td>
                    <td>
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </td>
                    <td>
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </td>
                  </tr>

                  <tr class="text-center">
                    <td class="font-weight-medium">Custom domains</td>
                    <td>
                      <v-icon>mdi-close</v-icon>
                    </td>
                    <td>
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </td>
                    <td>
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </td>
                    <td>
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td class="font-weight-medium">URL shortener (custom domains)</td>
                    <td>
                      <v-icon>mdi-close</v-icon>
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="green">mdi-check-circle</v-icon>
                        </template>
                        <span>
                          If you have a custom domain you can customize how it
                          works and
                          <br />add paths to it, e.g. mydomain.com/get ->
                          link-to.app/demo
                        </span>
                      </v-tooltip>
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="green">mdi-check-circle</v-icon>
                        </template>
                        <span>
                          If you have a custom domain you can customize how it
                          works and
                          <br />add paths to it, e.g. mydomain.com/get ->
                          link-to.app/demo
                        </span>
                      </v-tooltip>
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="green">mdi-check-circle</v-icon>
                        </template>
                        <span>
                          If you have a custom domain you can customize how it
                          works and
                          <br />add paths to it, e.g. mydomain.com/get ->
                          link-to.app/demo
                        </span>
                      </v-tooltip>
                    </td>
                  </tr>

                  <tr class="text-center">
                    <td class="font-weight-medium">
                      {{ $t("price") }} (monthly)
                    </td>
                    <td class="font-weight-medium">0</td>
                    <td class="font-weight-medium">{{ plans[1].price }}</td>
                    <td class="font-weight-medium">{{ plans[2].price }}</td>
                    <td class="font-weight-medium">{{ plans[3].price }}</td>
                  </tr>

                  <tr class="text-center">
                    <td></td>
                    <td>
                      <button-of-plan :planName="plans[0].name" />
                    </td>
                    <td>
                      <button-of-plan :planName="plans[1].name" />
                    </td>
                    <td>
                      <button-of-plan :planName="plans[2].name" />
                    </td>
                    <td>
                      <button-of-plan :planName="plans[3].name" />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>

    <div class="headline mb-2 mt-5">Receipts</div>
    <v-row>
      <v-col>
        <v-layout>
          <v-flex>
            <v-data-table height="300" disable-pagination disable-filtering :headers="transactionsHeaders"
              :items="transactions" :loading="transactionsLoading" hide-default-footer class="elevation-1">
              <template v-slot:item.amount="{ item }">
                {{ item.amount }} {{ item.currency }}
              </template>
              <template v-slot:item.receipt_url="{ item }">
                <v-btn text small :href="item.receipt_url" target="_blank">open</v-btn>
              </template>
            </v-data-table>
            <download-csv :data="transactions" class="my-3" name="receipts_1link.csv">
              <v-btn text>CSV Export</v-btn>
            </download-csv>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ButtonOfPlan from "../components/ButtonOfPlan";
import { mapState } from "vuex";
import { listTxs, getPaddleSubscription } from "../api";
import JsonCSV from "vue-json-csv";
import capitalize from "lodash/capitalize";

export default {
  components: {
    ButtonOfPlan,
    // eslint-disable-next-line vue/no-unused-components
    "download-csv": JsonCSV,
  },
  data() {
    return {
      paddleUser: undefined,
      transactionsLoading: true,
      openingPaddleUpdateUrl: false,
      transactionsHeaders: [
        {
          text: "ID",
          value: "order_id",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Created Date",
          value: "created_at",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Receipt",
          value: "receipt_url",
        },
      ],
      transactions: [],
    };
  },
  computed: mapState(["plans", "userPlan"]),
  mounted() {
    this.init();
  },
  methods: {
    capitalize(str) {
      return capitalize(str);
    },
    getDayDate(fullIsoDate) {
      return fullIsoDate.split("T")[0];
    },
    async init() {
      await this.$store.dispatch("getPlans");
      await this.$store.dispatch("getUserPlan");
      this.$store.commit("setIsLoading", false);
      this.fetchPaddleUser();
      this.getTransactions();
    },
    async getTransactions() {
      this.transactionsLoading = true;
      const list = await listTxs();
      this.transactions = list;
      this.transactionsLoading = false;
    },
    async fetchPaddleUser() {
      const res = await getPaddleSubscription();

      this.paddleUser = res;
    },
    async openPaddleUpdateUrl() {
      try {
        this.openingPaddleUpdateUrl = true;

        // fetch update url
        const res = await getPaddleSubscription();

        const paddleUpdateUrl = res ? res.update_url : undefined;
        if (typeof paddleUpdateUrl !== "string") {
          this.openingPaddleUpdateUrl = false;
          window.alert(
            "Unable to retrieve your subscription, check your latest emails to update the payment method"
          );
        }

        // open paddle widget
        window.Paddle.Checkout.open({
          override: paddleUpdateUrl,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.openingPaddleUpdateUrl = false;
      }
    },
  },
};
</script>
