<template>
  <div>
    <div class="text-center">
      <div>
        <v-img
          :src="require('../assets/email_inbox.png')"
          class="my-3 mt-8"
          contain
          height="200"
          alt="email inbox"
        ></v-img>
      </div>
      <h1 class="grey--text text--darken-2 mt-10">Verify your email address</h1>
      <p class="grey--text text--darken-2 text-subtitle">
        Please check your inbox, we have sent a verification email to
        {{ email }}
      </p>

      <v-container fluid>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" sm="4" md="4" xl="2">
            <v-btn
              small
              class="mb-16"
              depressed
              block
              @click="resendVerificationEmail"
              :disabled="!enableEmailVerificationResendButton"
            >
              Resend verification email
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { auth, sendEmailVerification } from "../auth";

export default {
  data() {
    return {
      email: "loading...",
      activeInterval: undefined,
      enableEmailVerificationResendButton: false,
    };
  },
  computed: {
    ...mapGetters(["getUserEmail", "getUserName"]),
  },
  beforeDestroy() {
    if (this.activeInterval) {
      clearInterval(this.activeInterval);
    }
  },
  mounted() {
    this.email = this.getUserEmail;

    this.$store.commit("setIsLoading", false);

    // start interval to check if user verified the email
    this.activeInterval = setInterval(() => {
      console.log("reloading user...");
      auth()
        .currentUser.reload()
        .then(() => {
          // get updated user
          const userAfter = auth().currentUser;
          console.log("emailVerified", userAfter.emailVerified);
          if (userAfter.emailVerified === true) {
            this.$store.commit("setUser", userAfter);
            this.$router.push("/links");
          }
        });

      // TODO if verified is true and user metadata is not populated go to almost done page
      //      otherwise go to links page
    }, 8000); // 8 secs

    // enable resend button after 15 seconds (it starts disabled because we just sent it)
    setTimeout(() => {
      this.enableEmailVerificationResendButton = true;
    }, 15000);
  },
  methods: {
    async resendVerificationEmail() {
      // disable button on click
      this.enableEmailVerificationResendButton = false;

      // send
      await sendEmailVerification();
      window.alert("Verification email sent");

      // re enable after 18 secs
      setTimeout(() => {
        this.enableEmailVerificationResendButton = true;
      }, 18000);
    },
  },
};
</script>
