<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline"
          >{{ pathEdit === true ? "Edit" : "Add new" }} URL alias</v-card-title
        >

        <v-card-text style="font-size: 1.2em">
          When clicking on
          <div class="py-2">
            <span class="font-weight-medium">{{ domainId }}</span
            >/
            <input
              type="text"
              class="domainInput"
              v-model="pathInput.path"
              :disabled="pathEdit === true"
              style="max-width: 220px"
            />
            <p class="subtitle-1" v-if="pathEdit === true">
              Cannot change a path of a created alias
            </p>
          </div>
          user will be redirected to
          <br />
          <input
            type="text"
            class="domainInput"
            v-model="pathInput.redirect_url"
            style="width: 100%"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn color="green warning-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn color="green darken-1" text @click="createOrUpdate">{{
            pathEdit === true ? "Update" : "Add"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="headline mb-2 mt-5">Connected Domains</div>
    <p>
      List of domains that are connected to your account, they can be used to
      create links or URL aliases.
      <br />To connect a new domain please follow the steps listed
      <a
        href="https://1link.helpsite.com/articles/25728-how-can-i-add-a-custom-domain"
        target="_blank"
        >here</a
      >
    </p>
    <p
      v-if="!userDomains || userDomains.length === 0"
      class="subtitle-2 grey--text text-center"
    >
      No domains found
    </p>

    <v-row class="mt-10" v-else>
      <v-card
        v-for="(domainObj, i) of userDomains"
        :key="'domain_' + i"
        class="mx-auto mb-10"
        width="600"
      >
        <v-card-text>
          <p class="display-1 text--primary">{{ domainObj.domain }}</p>
          <div v-if="domainObj.paths" class="black--text">
            <v-row
              v-for="(thisPath, j) of domainObj.paths"
              :key="'domain_' + i + '_path_' + j"
            >
              <v-col cols="4">{{ thisPath.path }}</v-col>
              <v-col cols="1">→</v-col>
              <v-col cols="5">{{ thisPath.redirect_url }}</v-col>
              <v-col cols="2">
                <v-btn
                  x-small
                  fab
                  color="secondary"
                  outlined
                  @click="openDialogForEdit(thisPath, domainObj.domain)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  fab
                  class="ml-2"
                  color="warning darken-3"
                  outlined
                  @click="deleteAlias(thisPath, domainObj.domain)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
          </div>
          <v-btn
            small
            outlined
            block
            color="primary"
            class="mt-4"
            @click="openDialogForCreation(domainObj.domain)"
            >Add URL Alias</v-btn
          >
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import {
  getDomains,
  createAliasUrl,
  updateAliasUrl,
  deleteAliasUrl,
} from "../api";
import { mapState, mapGetters } from "vuex";

const initialPathState = {
  path: null,
  redirect_url: null,
};

export default {
  data() {
    return {
      dialog: false,
      domainId: null,
      pathInput: initialPathState,
      pathEdit: false,
    };
  },
  mounted() {
    Promise.all([this.$store.dispatch("getAvailableDomains")]).finally(
      async () => {
        this.$store.commit("setIsLoading", false);
      }
    );
  },
  computed: {
    ...mapState(["userDomains"]),
  },
  methods: {
    async deleteAlias(data, domain) {
      const confirm = window.confirm(
        "Are you sure you want to delete the path: " +
          domain +
          data.path +
          "  ? this can have an impact on your current users"
      );
      if (confirm === true) {
        try {
          const res = await deleteAliasUrl(domain, data.path);
          this.$store.dispatch("getAvailableDomains");
        } catch (err) {
          window.alert(
            "error while deleting the alias url: " + err.response.data.message
          );
        }
      }
    },
    openDialogForCreation(domain) {
      this.domainId = domain;
      this.pathInput = Object.assign({}, initialPathState);
      this.pathEdit = false;
      this.dialog = true;
    },
    openDialogForEdit(data, domain) {
      const copy = JSON.parse(JSON.stringify(data));
      copy.path = copy.path.replace("/", "");
      this.pathInput = copy;
      this.pathEdit = true;
      this.domainId = domain;
      this.dialog = true;
    },
    createOrUpdate() {
      if (this.pathEdit === true) {
        this.update();
      } else {
        this.create();
      }
    },
    async update() {
      try {
        const res = await updateAliasUrl(
          this.domainId,
          this.pathInput.path,
          this.pathInput.redirect_url
        );
        this.$store.dispatch("getAvailableDomains");
        this.dialog = false;
      } catch (err) {
        window.alert(
          "error while updating alias url: " + err.response.data.message
        );
      }
    },
    async create() {
      try {
        const res = await createAliasUrl(
          this.domainId,
          this.pathInput.path,
          this.pathInput.redirect_url
        );
        this.$store.dispatch("getAvailableDomains");
        this.dialog = false;
      } catch (err) {
        window.alert(
          "error while creating alias url: " + err.response.data.message
        );
      }
    },
  },
};
</script>
<style scoped>
.domainInput {
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 2px 6px;
}
</style>
