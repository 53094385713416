<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div class="title mt-4">Processing, please wait</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$store.commit("setIsLoading", false);
    // after 5 seconds go back to plan, webhook should have been called
    setTimeout(() => {
      this.$router.push("/plan");
      location.reload();
    }, 5001);
  },
};
</script>
