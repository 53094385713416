export default {
  dashboard: "Panoramica",
  your_plan: "Il tuo piano",
  plan: "Piano | Piani",
  receipt: "Ricevuta | Ricevute",
  account: "Account",
  terms: "Termini",
  link: "Link | Links",
  privacy: "Privacy",
  support: "Supporto",
  upgrade: "Iscriviti",
  downgrade: "Iscriviti",
  limit: "Limite | Limiti",
  price: "Prezzo",
  available_plans: "Piani disponibili",
  login: "Accedi",
  signup: "Registrati",
  language: "Lingua",
  loading: "Caricamento",
  resetPassword: "Reset della password",
  emailPswWrong: "Email o password errata",
  confirmPassword: "Conferma password",
  logout: "Logout",
};
