<template>
  <v-container>
    <div class="headline mb-2 mt-5">Integrations</div>
    <p>
      Manage API Keys and learn more about integrating 1LINK.IO API into your
      business.
    </p>

    <v-card elevation="1">
      <v-card-title class="pb-2">Zapier</v-card-title>

      <v-card-text>
        Easy automation, with 1LINK.IO "Zap" you can create and manage links in
        your Zapier workflow.
        <v-spacer />
        <v-btn
          href="https://zapier.com/developer/invite/91269/a7d4edc6cc41d30408bdda77fb0223d0/"
          target="_blank"
          depressed
          class="mt-4"
          color="secondary"
          >Get the Zap
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card elevation="1" class="mt-10">
      <v-card-title class="pb-2">API Keys</v-card-title>

      <v-card-text>
        Create highly customized integrations using our easy to use REST API
        <v-btn
          href="https://api.1link.io/swagger"
          target="_blank"
          depressed
          x-small
          >API Documentation (OpenAPI)
          <v-icon right>mdi-chevron-right</v-icon>
        </v-btn>

        <p>
          API Keys are used to access 1LINK.IO Service programmatically, keep
          them private.
        </p>

        <v-list-item
          two-line
          v-for="(thisApiKeyData, i) in apiKeyList"
          v-bind:key="'api-key-index-' + i"
        >
          <v-list-item-icon class="pt-1 mr-4">
            <v-icon @click="deleteApiKey(thisApiKeyData.api_key)"
              >mdi-delete</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field
                :value="thisApiKeyData.api_key"
                outlined
                dense
                readonly
                class="apiKeyList"
                hide-details
                @click="$event.target.select()"
              ></v-text-field>
            </v-list-item-title>
            <v-list-item-subtitle>{{
              thisApiKeyData.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="createNewApiKey" color="primary" outlined class="mt-4">
          <v-icon left>mdi-plus</v-icon>

          Create new API Key
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { listApiKeys, createApiKey, deleteApiKey } from "../api";

export default {
  data() {
    return {
      apiKeyList: [],
    };
  },
  mounted() {
    this.fetchApiKeys().finally(async () => {
      this.$store.commit("setIsLoading", false);
    });
  },
  methods: {
    async selectAll() {
      this.$refs.input.select();
    },
    async fetchApiKeys() {
      const apiKeyList = await listApiKeys();
      this.apiKeyList = apiKeyList.result || [];
    },
    async createNewApiKey() {
      const apiKeyDescription = window.prompt(
        "You can enter an optional note/description for this API Key"
      );

      // null if user click Cancel
      if (apiKeyDescription === null) {
        return;
      }

      // converts "" -> undefined
      const description = apiKeyDescription || undefined;

      let operationPerformed = false;
      try {
        await createApiKey(description);

        operationPerformed = true;
      } catch (err) {
        window.alert(
          "Failed to create API KEY, please try again, if the error persists contact support"
        );
      }

      await this.fetchApiKeys();

      if (operationPerformed) {
        window.alert("Api key created successfully");
      }
    },
    async deleteApiKey(apiKey) {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete the api key "${apiKey}" ? This action is irreversible and will impact any services that is using this api key.`
      );

      if (confirmDelete !== true) {
        return;
      }

      let operationPerformed = false;
      try {
        await deleteApiKey(apiKey);
        operationPerformed = true;
      } catch (err) {
        window.alert(
          "Failed to delete API KEY, please try again, if the error persists contact support"
        );
      }

      await this.fetchApiKeys();

      if (operationPerformed) {
        window.alert("Api key deleted successfully");
      }
    },
  },
};
</script>
<style scoped>
.domainInput {
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 2px 6px;
}

.apiKeyList {
  max-width: 440px;
}
</style>
