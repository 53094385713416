<template>
  <div class="row">
    <div class="col-lg-12 col-md-6">
      <h2>Terms of Use and Service</h2>
      <ol>
        <li>
          <p>Terms</p>
          <p>
            By accessing the website at {{ config.BASE_URL }} or
            {{ config.APP_URL }}, you are agreeing to be bound by these terms of
            service, all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws. If you do
            not agree with any of these terms, you are prohibited from using or
            accessing this site.
          </p>
        </li>
        <li><p>Use License</p></li>
      </ol>
      <pre><code>  Permission <span class="hljs-keyword">is</span> granted <span class="hljs-keyword">to</span> temporarily download one <span class="hljs-keyword">copy</span> <span class="hljs-keyword">of</span> <span class="hljs-keyword">the</span> materials (information <span class="hljs-keyword">or</span> software) <span class="hljs-keyword">on</span> {{config.COMPANY_NAME}}-'s website <span class="hljs-keyword">for</span> personal, non-commercial transitory viewing only. This <span class="hljs-keyword">is</span> <span class="hljs-keyword">the</span> grant <span class="hljs-keyword">of</span> a license, <span class="hljs-keyword">not</span> a transfer <span class="hljs-keyword">of</span> title, <span class="hljs-keyword">and</span> under this license you may <span class="hljs-keyword">not</span>:


    modify <span class="hljs-keyword">or</span> <span class="hljs-keyword">copy</span> <span class="hljs-keyword">the</span> materials;
    use <span class="hljs-keyword">the</span> materials <span class="hljs-keyword">for</span> any commercial purpose, <span class="hljs-keyword">or</span> <span class="hljs-keyword">for</span> any public display (commercial <span class="hljs-keyword">or</span> non-commercial);
    attempt <span class="hljs-keyword">to</span> decompile <span class="hljs-keyword">or</span> <span class="hljs-built_in">reverse</span> engineer any software contained <span class="hljs-keyword">on</span> {{config.COMPANY_NAME}}-'s website;
    remove any copyright <span class="hljs-keyword">or</span> other proprietary notations <span class="hljs-keyword">from</span> <span class="hljs-keyword">the</span> materials; <span class="hljs-keyword">or</span>
    transfer <span class="hljs-keyword">the</span> materials <span class="hljs-keyword">to</span> another person <span class="hljs-keyword">or</span> <span class="hljs-string">"mirror"</span> <span class="hljs-keyword">the</span> materials <span class="hljs-keyword">on</span> any other server.


This license shall automatically terminate <span class="hljs-keyword">if</span> you violate any <span class="hljs-keyword">of</span> these restrictions <span class="hljs-keyword">and</span> may be terminated <span class="hljs-keyword">by</span> {{config.COMPANY_NAME}}- <span class="hljs-keyword">at</span> any <span class="hljs-built_in">time</span>. Upon terminating your viewing <span class="hljs-keyword">of</span> these materials <span class="hljs-keyword">or</span> upon <span class="hljs-keyword">the</span> termination <span class="hljs-keyword">of</span> this license, you must destroy any downloaded materials <span class="hljs-keyword">in</span> your possession whether <span class="hljs-keyword">in</span> electronic <span class="hljs-keyword">or</span> printed format.
</code></pre>
      <ol>
        <li>Disclaimer</li>
      </ol>
      <pre><code>The materials <span class="hljs-keyword">on</span> {{config.COMPANY_NAME}}-'s website are provided <span class="hljs-keyword">on</span> an '<span class="hljs-keyword">as</span> <span class="hljs-keyword">is</span>' basis. {{config.COMPANY_NAME}}- makes no warranties, expressed <span class="hljs-keyword">or</span> implied, <span class="hljs-keyword">and</span> hereby disclaims <span class="hljs-keyword">and</span> negates all other warranties including, <span class="hljs-keyword">without</span> limitation, implied warranties <span class="hljs-keyword">or</span> conditions <span class="hljs-keyword">of</span> merchantability, fitness <span class="hljs-keyword">for</span> a particular purpose, <span class="hljs-keyword">or</span> non-infringement <span class="hljs-keyword">of</span> intellectual <span class="hljs-keyword">property</span> <span class="hljs-keyword">or</span> other violation <span class="hljs-keyword">of</span> rights.
Further, {{config.COMPANY_NAME}}- <span class="hljs-keyword">does</span> <span class="hljs-keyword">not</span> warrant <span class="hljs-keyword">or</span> make any representations concerning <span class="hljs-keyword">the</span> accuracy, likely results, <span class="hljs-keyword">or</span> reliability <span class="hljs-keyword">of</span> <span class="hljs-keyword">the</span> use <span class="hljs-keyword">of</span> <span class="hljs-keyword">the</span> materials <span class="hljs-keyword">on</span> <span class="hljs-keyword">its</span> website <span class="hljs-keyword">or</span> otherwise relating <span class="hljs-keyword">to</span> such materials <span class="hljs-keyword">or</span> <span class="hljs-keyword">on</span> any sites linked <span class="hljs-keyword">to</span> this site.
By signing up <span class="hljs-keyword">and</span> using {{config.APP_NAME.toUpperCase()}} services you allow {{config.APP_NAME.toUpperCase()}} <span class="hljs-keyword">to</span> add your company logo <span class="hljs-keyword">in</span> our landing page ({{config.BASE_URL}}) <span class="hljs-string">"USED BY"</span> <span class="hljs-keyword">for</span> informational purposes only, <span class="hljs-keyword">to</span> remove an image you can contact us via email {{config.SUPPORT_EMAIL}}.
The materials, included images, logos, <span class="hljs-built_in">text</span> <span class="hljs-keyword">or</span> other digital assets found <span class="hljs-keyword">in</span> <span class="hljs-keyword">the</span> website are <span class="hljs-keyword">for</span> informational purposes only, <span class="hljs-keyword">and</span> belongs <span class="hljs-keyword">to</span> their legitimate owners, no copyright intended <span class="hljs-keyword">from</span> {{config.APP_NAME.toUpperCase()}}.
To request a removal <span class="hljs-keyword">of</span> an image you can contact us here {{config.SUPPORT_EMAIL}}
</code></pre>
      <ol>
        <li>
          <p>Limitations</p>
          <p>
            In no event shall {{ config.COMPANY_NAME }}- or its suppliers be
            liable for any damages (including, without limitation, damages for
            loss of data or profit, or due to business interruption) arising out
            of the use or inability to use the materials on
            {{ config.COMPANY_NAME }}-&#39;s website, even if
            {{ config.COMPANY_NAME }}- or a {{ config.COMPANY_NAME }}-
            authorized representative has been notified orally or in writing of
            the possibility of such damage. Because some jurisdictions do not
            allow limitations on implied warranties, or limitations of liability
            for consequential or incidental damages, these limitations may not
            apply to you.
          </p>
        </li>
        <li>
          <p>Accuracy of materials</p>
          <p>
            The materials appearing on {{ config.COMPANY_NAME }}-&#39;s website
            could include technical, typographical, or photographic errors.
            {{ config.COMPANY_NAME }}- does not warrant that any of the
            materials on its website are accurate, complete or current.
            {{ config.COMPANY_NAME }}- may make changes to the materials
            contained on its website at any time without notice. However
            {{ config.COMPANY_NAME }}- does not make any commitment to update
            the materials.
          </p>
        </li>
        <li>
          <p>Links</p>
          <p>
            {{ config.COMPANY_NAME }}- has not reviewed all of the sites linked
            to its website and is not responsible for the contents of any such
            linked site. The inclusion of any link does not imply endorsement by
            {{ config.COMPANY_NAME }}- of the site. Use of any such linked
            website is at the user&#39;s own risk.
          </p>
        </li>
        <li>
          <p>Modifications</p>
          <p>
            {{ config.COMPANY_NAME }}- may revise these terms of service for its
            website at any time without notice. By using this website you are
            agreeing to be bound by the then current version of these terms of
            service.
          </p>
        </li>
        <li>
          <p>Governing Law</p>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of Italy and you irrevocably submit to the
            exclusive jurisdiction of the courts in that State or location.
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>Customer Obligations</p>
          <p>
            Compliance and use. Customer will NOT do any of the following
            (collectively, the “Restricted Activities”):
          </p>
          <p>
            . Sell, resell, or lease, the
            {{ config.APP_NAME.toUpperCase() }} Services to a third party (or
            perform activities similar thereto),
          </p>
          <p>
            . Attempt to reverse engineer, decompile, copy, reproduce, or
            replicate the {{ config.APP_NAME.toUpperCase() }} Services or any
            component (including, any algorithms), or knowingly permit any of
            the foregoing,
          </p>
          <p>
            . Attempt to create a substitute or similar service through use of,
            by accessing, or by referencing, the
            {{ config.APP_NAME.toUpperCase() }} Services if not allowed,
          </p>
          <p>
            . Use the {{ config.APP_NAME.toUpperCase() }} Services for any
            illegal activities or fail to comply with any applicable laws
          </p>
          <p>. Create more than one account (for the same entity)</p>
          <p>
            . Redirect (3XX) or proxy requests from your own custom domain to
            1LINK.IO on the free plan.
          </p>
        </li>
        <li>
          <p>Custom domains</p>
          <p>
            A custom domain can be connected to the customer account only if:
          </p>
          <p>. The domain provided is no longer than 30 characters</p>
          <p>. The registration date is more than 30 days</p>
          <p>
            . (Optional, if a root domain connection is needed e.g.
            "example.com" instead of "app.example.com") The domain hosting
            chosen by the customer supports DNS apex.
          </p>
        </li>
        <li>
          <p>Payments, Refunds, Upgrading and Downgrading Terms</p>
          <p>
            Our order process is conducted by our online reseller Paddle.com.
            Paddle.com is the Merchant of Record for all our orders. Paddle
            provides all customer service inquiries and handles returns.
          </p>
          <p>
            . The Service is Offered with a free trial (on paid plans). Once
            that trial is ended, you will only be able to continue using the
            Service by paying in advance for additional usage. If you fail to
            pay for additional usage, your plan will be automatically downgraded
            to the free one.
          </p>
          <p>
            . For any upgrade or downgrade in plan level, will result in the new
            rate being charged at the next billing cycle, cycle calculations are
            done automatically by our payment processor Paddle.com
          </p>
          <p>
            . Downgrading your Service may cause the loss of features or
            capacity of your account. The Company does not accept any liability
            for such loss (direct or indirect).
          </p>
          <p>
            . Refund requests made after you have sent a payment are handled on
            a case by case basis and are issued at our sole discretion. Refund
            requests, if any, must be made within thirty (30) days of your
            original payment.
          </p>
          <p>
            . VAT and invoices are handled by our payment processor and Merchant
            of Records Paddle.com
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
import config from "../config";
export default {
  data() {
    return {
      config,
    };
  },
  mounted() {
    this.$store.commit("setIsLoading", false);
  },
};
</script>
