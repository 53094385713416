<template>
  <v-dialog v-model="dialog" max-width="960px">
    <v-dialog v-model="landingPageSettings" max-width="500">
      <v-card>
        <v-card-title>
          <v-row justify="space-between" align="center">
            <v-col>
              Landing page settings
            </v-col>

            <v-col>
              <v-tooltip v-if="
                modelView === 'editLink' &&
                itHasLandingPage === true &&
                desktopUserOption === 'landing_page'
              " bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :loading="regenerateLandingPageLoading" @click="regenerateLandingPage" v-on="on" outlined
                    style="float:right;" small>
                    <v-icon left>mdi-refresh</v-icon>
                    Regenerate
                  </v-btn>
                </template>
                <p style="max-width: 400px">If you have changed the app icon or description on the Play Store you can
                  trigger the
                  regeneration of the landing page, in this way the changes will be
                  reflected on the landing page</p>
              </v-tooltip>
            </v-col>

          </v-row>
        </v-card-title>

        <v-card-text class="mt-5">

          <v-alert class="my-7" color="blue" dense type="info" v-if="userPlan.plan.name == 'free'">Upgrade your plan to customize the
            landing
            page</v-alert>

          <div
            :style="{ opacity: userPlan.plan.name == 'free' ? 0.4 : undefined, pointerEvents: userPlan.plan.name == 'free' ? 'none' : undefined }">
            <v-text-field label="App title" outlined v-model="landingPageTitle"></v-text-field>

            <v-text-field label="App description" outlined v-model="landingPageDescription"></v-text-field>

            <v-text-field label="App image url" outlined
              hint="Must be the app icon uploaded to Google Play store or App Store"
              v-model="landingPageAppIconURL"></v-text-field>

            <div class="mt-3" style="display: flex;">
              <p class="mt-1"><label>App color</label></p>
              <input type="color" name="landing page color" class="ml-4" v-model="landingPageColor" />
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" plain @click="landingPageSettings = false">
            Cancel
          </v-btn>

          <v-btn :disabled="userPlan.plan.name == 'free'" color="green darken-1" text
            @click="() => { landingPageSettings = false; landingPageSettingsUpdate = true }">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card v-if="modelView === 'shareLink'">
      <v-container>
        <v-card-text>
          <p class="headline text-center">
            {{ shareDialogTitle || "Your Link is now live" }}
          </p>
          <v-row>
            <v-col align="center" justify="center">
              <v-text-field solo rounded v-model="fullUrl" color="primary" readonly height="50px"
                style="max-width: 380px; font-size: 1.4em" ref="fullUrlInput">
                <template slot="append">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon @click="copyFullUrl" v-on="on">mdi-content-copy</v-icon>
                    </template>
                    <span>Copy to clipboard</span>
                  </v-tooltip>
                </template>
                <template slot="append-outer">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" :href="fullUrl" target="_blank">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>Open link in a new tab</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="3">
              <img :src="qrCodeImgSrc" alt="qr code loading" />
            </v-col>
            <v-col cols="4">
              <v-btn class="mb-1" depressed block @click="downloadQrCode">Download QR Code image</v-btn>
              <br />
              <v-btn class="mb-1" depressed block :href="emailLink">Send link via E-mail</v-btn>
              <br />
              <v-btn class="mb-1" depressed block :href="twitterLink" target="_blank">Share link on Twitter</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">
          <v-icon class="mr-2">mdi-link</v-icon>
          <span v-if="modelView == 'createLink'">New Link</span>
          <span v-else>Edit {{ shortHandle }} Link</span>
        </span>
      </v-card-title>

      <v-card-text>
        <p class="text--secondary body-1">
          {{
            modelView == "createLink" ? "Create a new link" : "Edit your link"
          }}
          by providing Google Play Store and Apple App Store URLs (optionally
          you can also provide Huawei AppGallery URL for the devices that don't
          support Google Play Store), users will be automatically redirected.
        </p>
        <v-container>
          <p class="text-center body-1 mb-n2">Domain and Short Handle</p>
          <v-row>
            <v-col offset-lg="2" align="center" justify="center">
              <v-text-field class="shortHandleInput" v-model="shortHandle" label="Short Handle" height="50"
                hide-details="auto" :disabled="modelView == 'editLink'" :rules="shortHandleRules" required>
                <template slot="prepend">
                  <v-select :items="availableDomainsStrippedDown" v-model="domainSelected"
                    @click="reloadDomainsSelected" label="Domain" height="50" :disabled="modelView == 'editLink'" solo
                    dense required>
                    <v-icon slot="append-outer" large>mdi-slash-forward</v-icon>
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <!-- TODO open a model or dashboard page -->
                      <v-list-item href="https://1link.helpsite.com/articles/25728-how-can-i-add-a-custom-domain"
                        target="_blank">
                        <v-list-item-avatar color="grey--text">
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="grey--text text--darken-2">Add new domain</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Apple App Store iOS -->
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="iosUrl" name="ios_url" label="Apple App Store (iOS)"
                placeholder="URL to your app on the Apple App Store for iOS devices, it should start with: https://apps.apple.com/..."
                filled rounded prepend-icon="mdi-apple" :rules="iosUrlRules" required></v-text-field>
            </v-col>
          </v-row>

          <!-- Google Play Store Android -->
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="androidUrl" name="android_url" label="Google Play Store (Android)"
                placeholder="URL to your app on the Google Play Store for Android devices, it should start with: https://play.google.com/..."
                filled rounded prepend-icon="mdi-android" :rules="androidUrlRules" required></v-text-field>
            </v-col>
          </v-row>

          <!-- Huawei App Gallery -->
          <v-row align="center" justify="center">
            <v-col cols="3">
              <p class="mb-7">Optional (Huawei devices only)</p>
            </v-col>
            <v-col cols="9">
              <v-text-field v-model="huaweiAppGalleryUrl" name="huawei_app_gallery" label="Huawei App Gallery"
                placeholder="URL to your app on the Huawei App Gallery for Huawei devices that doesn't support Google Play Store, it should start with: https://appgallery.huawei.com/..."
                filled rounded :rules="huaweiAppGalleryUrlRules">
                <template slot="prepend">
                  <img src="../assets/huawei_app_gallery.png" class="huaweiAppGalleryIcon" />
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col cols="2">
              <p><v-icon>mdi-monitor</v-icon> Desktop users</p>
            </v-col>
            <v-col cols="10">
              <v-radio-group v-model="desktopUserOption">
                <v-radio value="landing_page" @click.native="setLandingPageOption">
                  <template v-slot:label>
                    <div><span>Landing Page</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :hidden="!itHasLandingPage" @click.stop="landingPageSettings = true" class="ml-4"
                            color="primary" outlined fab x-small v-bind="attrs" v-on="on">
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <span>Advanced Landing page settings</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-radio>
                <p class="text--secondary">
                  Show an automatically generated landing page that matches your
                  app style with App Store and Play Store links and a QR code -
                  for desktop users only.
                </p>
                <!-- <v-tooltip
                  v-if="
                    modelView === 'editLink' &&
                    itHasLandingPage === true &&
                    desktopUserOption === 'landing_page'
                  "
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="regenerateLandingPageLoading"
                      @click="regenerateLandingPage"
                      v-on="on"
                      outlined
                      class="mb-5"
                    >
                      <v-icon left>mdi-refresh</v-icon>
                      Regenerate landing page
                    </v-btn>
                  </template>
                  <span
                    >If you have changed the app icon or description you can
                    regenerate the landing page, in this way the changes will be
                    reflected also on the landing page (note: data is sourced
                    from your app on Google Play Store)</span
                  >
                </v-tooltip> -->

                <v-divider class="mb-4"></v-divider>
                <v-radio label="Redirect to website" value="desktop_url"></v-radio>
                <p class="text--secondary">
                  Redirect to an external website - for desktop users only.
                </p>
                <v-text-field v-model="desktopUrl" single-line solo label="URL Address" name="desktop_url"
                  placeholder="Enter the URL address where Desktop users will be redirected" id="url_address"
                  @click="desktopUserOption = 'desktop_url'" :hide-details="desktopUserOption === 'desktop_url' ? 'auto' : true
                    " :rules="desktopUrlRules"></v-text-field>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-expansion-panels :hover="true" flat>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <v-icon class="mr-2 grey--text">mdi-cog-outline</v-icon>
                    <span>Optional note</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea solo label="Notes for internal use" class="mt-4" v-model="note"></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <!--<v-spacer></v-spacer>-->
        <!--<v-btn color="blue darken-1" text @click="close">Cancel</v-btn>-->
        <v-btn class="mb-2" color="success" large rounded block @click="createOrUpdateLink"
          :disabled="isLinkValid !== true">{{ modelView === "createLink" ? "Create" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  newLink as newLinkRequest,
  listLinks,
  getLinkByLinkId,
  newLink,
  updateLink,
  downloadQrCodeOfLink,
  refreshLandingPage,
} from "../api";
import { Confetti } from "vue-confetti";
import { mapState } from "vuex";

function componentToHex(c) {
  var hex = Number(c).toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(rgb) {
  const [r, g, b] = rgb.replace('rgb(', '').replace(')', '').split(',').map((x) => x.trim())
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const omit = require("lodash/omit");

const confetti = new Confetti();

export default {
  data: () => ({
    regenerateLandingPageLoading: false,
    modelView: "createLink",
    shortHandleRules: [
      (v) => !!v || "Short handle is required",
      (v) =>
        new RegExp(/^[a-zA-Z0-9_-]*$/).test(v) ||
        "Allowed only alphanumeric characters and - _",
    ],
    iosUrlRules: [
      (v) => !!v || "App Store (iOS) url is required",
      (v) =>
        (v && v.startsWith("https://")) ||
        "App Store (iOS) url should start with https",
    ],
    androidUrlRules: [
      (v) => !!v || "Play Store (Android) url is required",
      (v) =>
        (v && v.startsWith("https://")) ||
        "Play Store (Android) url should start with https",
    ],
    huaweiAppGalleryUrlRules: [
      (v) => {
        if (v === null || v === "") {
          return;
        }
        if (v && !v.startsWith("https://")) {
          return "Huawei App Gallery url should start with https";
        }
      },
    ],
    desktopUrlRules: [
      (v) =>
        (!!v && v.startsWith("http")) ||
        "If 'Redirect to webiste' option is enabled enter a valid URL for desktop users (starts with https)",
    ],
    note: "",
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    iosUrl: null,
    androidUrl: null,
    huaweiAppGalleryUrl: null,
    originalHuaweiAppGalleryUrl: null,
    desktopUrl: null,
    fullUrl: "",
    shortHandle: "", // should be random at init if create
    desktopUserOption: "landing_page",
    dialog: false,
    editLinkId: null,
    shareDialogTitle: null,
    domainSelected: "link-to.app",
    shareLinkId: 0,
    itHasLandingPage: false,
    landingPageSettings: false,
    landingPageSettingsUpdate: false,
    landingPageColor: "",
    landingPageTitle: "",
    landingPageAppIconURL: "",
  }),
  beforeDestroy() {
    try {
      if (confetti) {
        confetti.remove();
      }
    } catch (err) { }
  },
  computed: {
    ...mapState(["availableDomainsStrippedDown", "userPlan"]),
    emailLink() {
      return `mailto:?subject=New link generated&body=I've generated a new link on 1link.io: ${this.fullUrl}`;
    },
    qrCodeImgSrc() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&margin=8&data=${this.fullUrl}?via_qrcode=1`;
    },
    twitterLink() {
      return `https://twitter.com/share?text=Download our app&url=${this.fullUrl}`;
    },
    /**
     * will return a string error or true if is valid
     */
    isLinkValid() {
      if (!this.shortHandle) {
        return "short handle is mandatory";
      }

      if (!this.iosUrl) {
        return "App Store url (iOS) is mandatory";
      }

      if (!this.androidUrl) {
        return "Play Store url (Android) is mandatory";
      }

      if (this.desktopUserOption === "desktop_url") {
        if (!this.desktopUrl) {
          return "Desktop url is mandatory";
        }
      }

      return true;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init(editLinkId) {
      this.shareDialogTitle = null;
      // if is create
      if (!editLinkId) {
        this.itHasLandingPage = false;
        this.modelView = "createLink";
        this.androidUrl = "";
        this.iosUrl = "";
        this.desktopUrl = "";
        this.huaweiAppGalleryUrl = null;
        this.desktopUserOption = "landing_page";
        this.shortHandle = this.makeid(10);
        this.note = "";
        this.domainSelected = this.userPlan.user.default_domain_namespace;
      } else {
        // fetch link by id and load data
        this.modelView = "editLink";
        const link = await getLinkByLinkId(editLinkId);
        this.itHasLandingPage =
          link &&
          link.landing_page !== null &&
          typeof link.landing_page === "object" &&
          typeof link.landing_page.title === "string";
        console.log("itHasLandingPage", this.itHasLandingPage);
        this.editLinkId = editLinkId;
        this.androidUrl = link.android.url;
        this.huaweiAppGalleryUrl = link.huawei_app_gallery
          ? link.huawei_app_gallery.url
          : null;
        this.originalHuaweiAppGalleryUrl =
          link.huawei_app_gallery &&
            JSON.stringify(link.huawei_app_gallery) !== "{}"
            ? link.huawei_app_gallery.url
            : null;
        this.iosUrl = link.ios.url;
        this.desktopUrl = link.desktop_url || null;
        if (typeof this.desktopUrl === "string") {
          this.desktopUserOption = "desktop_url";
        } else {
          this.desktopUserOption = "landing_page";
        }
        this.note = link.note;
        this.domainSelected = link.domain_namespace;
        this.shortHandle = link.short_handle;

        if (this.itHasLandingPage) {
          this.landingPageColor = link.landing_page.main_color.startsWith('#') ? link.landing_page.main_color : rgbToHex(link.landing_page.main_color)
          this.landingPageTitle = link.landing_page.title
          this.landingPageDescription = link.landing_page.short_description
          this.landingPageAppIconURL = link.landing_page.icon_url
        }
      }
    },
    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
    openShareDialog(fullUrl, linkId) {
      this.fullUrl = fullUrl;
      this.shareLinkId = linkId;
      this.modelView = "shareLink";
      this.shareDialogTitle = "Share link";
      this.open();
    },
    copyFullUrl() {
      console.log("clipboard copy triggered");
      const textToCopy = this.$refs.fullUrlInput.$el.querySelector("input");
      textToCopy.select();
      this.$clipboard(this.fullUrl + "");
    },
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async regenerateLandingPage() {
      this.regenerateLandingPageLoading = true;
      // window.alert('refresh' + this.editLinkId);
      const linkId = this.editLinkId;
      try {
        const res = await refreshLandingPage(linkId);
        window.alert(
          "Successfully regenerated landing page, the changes should be live in few minutes"
        );
      } catch (err) {
        window.alert(
          "failed to regnerate landing page " + err.response.data.message
        );
      }
      this.regenerateLandingPageLoading = false;
    },
    async downloadQrCode() {
      const shortHandleFromFullUrl = this.fullUrl.split("/").pop();
      await downloadQrCodeOfLink(this.fullUrl, shortHandleFromFullUrl);
    },
    setLandingPageOption() {
      // if is edit do not delete the val
      if (this.editLinkId && typeof this.desktopUrl === "string") {
        const res = window.confirm(
          "by selecting this option it will clear the desktop url entered, do you want to continue?"
        );
        if (!res) {
          this.desktopUserOption = "desktop_url";
          return false;
        }
      }
      this.desktopUrl = null;
    },
    async createOrUpdateLink() {
      const linkError = this.isLinkValid;
      if (linkError !== true) {
        window.alert(linkError);
        return;
      }
      const cleanLink = {
        short_handle: this.shortHandle,
        ios: { url: this.iosUrl },
        android: { url: this.androidUrl },
      };

      // add huawei app gallery if present
      if (this.huaweiAppGalleryUrl && this.huaweiAppGalleryUrl.length > 0) {
        cleanLink.huawei_app_gallery = {
          url: this.huaweiAppGalleryUrl,
        };
      }

      if (this.desktopUserOption === "desktop_url") {
        cleanLink.desktop_url = this.desktopUrl;
      }

      if (this.domainSelected) {
        cleanLink.domain_namespace = this.domainSelected;
      }

      if (this.note) {
        cleanLink.note = this.note;
      }

      let res;
      if (this.modelView === "createLink") {
        this.shareDialogTitle = "Your Link is now live";
        res = await newLinkRequest(cleanLink).catch((err) => {
          window.alert(err.response.data.message);
        });
      } else if (this.modelView === "editLink") {
        this.shareDialogTitle = "Your link is now updated and live";
        cleanLink.note = this.note;
        cleanLink.desktop_url = this.desktopUrl;

        // landing page settings changed
        if (this.landingPageSettingsUpdate === true) {
          cleanLink.landing_page = {
            title: this.landingPageTitle,
            short_description: this.landingPageDescription,
            icon_url: this.landingPageAppIconURL,
            main_color: this.landingPageColor,
          }
        }

        // huawei app gallery removed
        if (
          !cleanLink.huawei_app_gallery &&
          this.originalHuaweiAppGalleryUrl !== null &&
          !this.huaweiAppGalleryUrl
        ) {
          cleanLink.huawei_app_gallery = null;
        }

        const editLinkReq = omit(cleanLink, "short_handle", "domain_namespace");
        res = await updateLink(this.editLinkId, editLinkReq).catch((err) => {
          window.alert(err.response.data.message);
        });
      }

      if (res.status === 200) {
        this.fullUrl = res.data.full_url;
        const prevView = this.modelView;
        this.modelView = "shareLink";
        // run only on desktop / widescreen and on create
        if (window.innerWidth >= 1300 && prevView === "createLink") {
          confetti.start({ particlesPerFrame: 0.5 });
          setTimeout(() => {
            confetti.stop();
          }, 2400);
        }
        this.$vueEventBus.$emit("linkListFetch", 1);
      } else {
        window.alert(res.data.message);
      }
      console.log(res);
    },
  },
};
</script>
<style>
.shortHandleInput>.v-input__control {
  max-width: 320px !important;
}

.shortHandleInput>.v-input__prepend-outer>.v-input>.v-input__control {
  max-width: 250px !important;
}

.shortHandleInput input {
  font-size: 1.3em !important;
}

#confetti-canvas {
  z-index: 1000;
}

.huaweiAppGalleryIcon {
  width: 31px;
  margin-top: -4px;
}
</style>
