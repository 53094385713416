<template>
  <v-app>
    <Snackbar />
    <v-navigation-drawer
      :right="$store.state.locale === 'ar'"
      v-model="drawer"
      temporary
      app
      v-if="!$route.meta.removeBanner"
    >
      <!--
      <v-list dense>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("dashboard") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    -->

      <v-list-item to="/links" :disabled="isEmailVerified2022 === false">
        <v-list-item-action>
          <v-icon>mdi-link</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("link", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/domains" :disabled="isEmailVerified2022 === false">
        <v-list-item-action>
          <v-icon>mdi-earth</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Domains</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/integrations" :disabled="isEmailVerified2022 === false">
        <v-list-item-action>
          <v-icon>mdi-cable-data</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Integrations</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/plan" :disabled="isEmailVerified2022 === false">
        <v-list-item-action>
          <v-icon>mdi-wallet</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/account" :disabled="isEmailVerified2022 === false">
        <v-list-item-action>
          <v-icon>mdi-account</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("account") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
      <v-list-item @click="logout" id="logout-btn">
        <v-list-item-action>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--
      <v-list-item class="mt-3">
        <v-select
          v-model="$store.state.locale"
          item-text="text"
          item-value="value"
          prepend-icon="mdi-translate"
          :items="languageSelect"
          :label="$t('language')"
          @input="changeLanguage"
        />
      </v-list-item>
  -->
    </v-navigation-drawer>

    <v-app-bar app v-if="!$route.meta.removeBanner">
      <v-app-bar-nav-icon @click="clickNavIcon()" id="expand-panel-btn" />
      <v-toolbar-title>
        <router-link to="/">
          <img :src="$store.state.config.LOGO_URL" class="logo" />
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <v-content>
      <v-overlay
        color="#ffffff"
        light
        :value="$store.state.isLoading"
        opacity="1"
        z-index="100"
      >
        <v-progress-circular
          indeterminate
          color="blue lighten-4"
          size="64"
        ></v-progress-circular>
        <p class="mt-5 grey--text">{{ $t("loading") }}</p>
      </v-overlay>
      <router-view></router-view>
    </v-content>

    <v-footer class="body-2">
      <!--
      <div>
        <label><v-icon>mdi-translate</v-icon> {{ $t("language") }}</label
        >&nbsp;
        <select
          class="select-lang"
          v-bind:value="$store.state.locale"
          @change="handleLanguageChange($event)"
        >
          <option
            v-for="(item, index) in languageSelect"
            :key="'select-lang-' + index"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
      </div>
      
      <v-divider
        vertical
        class="mx-4 d-none d-xs-flex d-sm-flex d-md-flex d-lg-flex"
      />
      -->
      <div>
        <v-btn
          text
          small
          outlined
          class="light-blue lighten-5"
          :href="'mailto:' + $store.state.config.SUPPORT_EMAIL"
          >{{ $t("support") }}</v-btn
        >
        <v-btn
          text
          small
          class="grey--text text--darken-3"
          href="/#/privacy-policy"
          target="_blank"
          >{{ $t("privacy") }}</v-btn
        >
        <v-btn
          text
          small
          class="grey--text text--darken-3"
          href="/#/terms-of-service"
          target="_blank"
          >{{ $t("terms") }}</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div>
        {{ new Date().getFullYear() }} &copy;
        {{ $store.state.config.COMPANY_NAME }}
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "./components/Snackbar";
import config from "./config";
import { logout } from "./auth";

export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    drawer: false,
    languageSelect: config.LANGUAGE_SELECT,
  }),
  computed: {
    ...mapGetters(["isEmailVerified2022", "isEmailVerified"]),
  },
  methods: {
    async logout() {
      await logout();
      window.location.href = config.WEBSITE_URL;
    },
    clickNavIcon() {
      this.drawer = !this.drawer;
    },
    changeLanguage(newLang) {
      this.$store.commit("changeLanguage", newLang);
    },
    handleLanguageChange(ev) {
      this.changeLanguage(ev.target.value);
    },
  },
};
</script>

<style>
.logo {
  margin-top: 5px;
  max-height: 43px;
}
footer {
  padding-top: 40px;
}
.select-lang {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}
</style>
