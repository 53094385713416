var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('div',[_c('v-img',{staticClass:"my-3 mt-8",attrs:{"src":require('../assets/almost_done.svg'),"contain":"","height":"200","alt":"almost done"}})],1),_c('h1',{staticClass:"grey--text text--darken-2 mt-10"},[_vm._v("We are almost there")]),_c('p',{staticClass:"grey--text text--darken-1 text-subtitle"},[_vm._v(" Your email is now confirmed, please answer the questions below, this allows us to provide a better service to you. Thanks ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3","xl":"2"}},[_c('v-subheader',[_vm._v(" Company size ")])],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3","xl":"2"}},[_c('v-select',{attrs:{"items":['1-20', '21-50', '51-100', '101-200', '200+'],"label":"Select","persistent-hint":"","single-line":""},model:{value:(_vm.companySize),callback:function ($$v) {_vm.companySize=$$v},expression:"companySize"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3","xl":"2"}},[_c('v-subheader',[_vm._v(" Company main sector ")])],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3","xl":"2"}},[_c('v-select',{attrs:{"items":[
              'IT / Software',
              'Marketing',
              'Finance',
              'Healthcare',
              'Construction',
              'Food Services',
              'Accommodation',
              'Retail Trade',
              'Other' ],"label":"Select","persistent-hint":"","single-line":""},model:{value:(_vm.companyMainSector),callback:function ($$v) {_vm.companyMainSector=$$v},expression:"companyMainSector"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3","xl":"2"}},[_c('v-subheader',[_vm._v("Where do you saw 1LINK.IO ?")])],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3","xl":"2"}},[_c('v-select',{attrs:{"items":[
              'Product Hunt',
              'Capterra',
              'Reddit',
              'SaaSHub',
              'Conference / Event',
              'AlternativeTo.net',
              'Word of mouth',
              'Google / Search',
              'Other' ],"label":"Select","persistent-hint":"","single-line":""},model:{value:(_vm.attributionSource),callback:function ($$v) {_vm.attributionSource=$$v},expression:"attributionSource"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4","xl":"2"}},[_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"green","block":""},on:{"click":_vm.clickUpdateMetadata}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","small":"","to":"/links"}},[_vm._v("Skip")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }