import axios from "axios";
import config from "./config";
import { getJwtToken } from "./auth";
const fileDownload = require("js-file-download");

const axiosInstance = axios.create();

// set default
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
axiosInstance.defaults.baseURL =
  process.env.NODE_ENV == "development"
    ? "http://localhost:3000"
    : config.API_BASE_URL;

// set auth token before request
axiosInstance.interceptors.request.use(async (config) => {
  console.log("calling", config.method, config.url, "with", config.data);
  const token = await getJwtToken();
  config.headers.Authorization = "Bearer " + token;
  return config;
});

export async function getPlans() {
  const result = await axiosInstance.get("/paddle/plan");
  return result.data;
}

export async function getUserPlan() {
  const result = await axiosInstance.get("/user/");
  return result.data;
}

export async function initAccount() {
  const result = await axiosInstance.post("/user/signup");
  return result.data;
}

export async function movePlan(name, churnData) {
  try {
    const result = await axiosInstance.post("/paddle/move/" + name, churnData);
    return result.data;
  } catch (err) {
    console.log(err);
    return {
      success: false,
    };
  }
}

export async function listTxs() {
  const result = await axiosInstance.get("/paddle/transactions");
  return result.data;
}

export async function getPaddleSubscription() {
  const result = await axiosInstance.get("/paddle/subscription");
  return result.data;
}

export async function newLink(data) {
  const result = await axiosInstance.post("/link", data);
  return result;
}

export async function updateLink(id, data) {
  const result = await axiosInstance.put("/link/id/" + id, data);
  return result;
}

export async function downloadQrCodeOfLink(fullUrl, shortHandle) {
  const result = await axios.get(
    "https://api.qrserver.com/v1/create-qr-code/?size=400x400&margin=10&data=" +
      fullUrl +
      "?via_qrcode=1",
    {
      responseType: "blob",
    }
  );
  fileDownload(result.data, `1link_qrcode_${shortHandle}.png`);
  return;
}

export async function listLinks(ops) {
  const query = {};
  if (ops.keyword) {
    query.keyword = ops.keyword;
  } else if (ops.take) {
    query.skip = ops.skip;
    query.take = ops.take;
  }
  console.log("query to send", query);
  const result = await axiosInstance.get("/link", { params: query });
  return result.data;
}

export async function getLinkByLinkId(id) {
  const result = await axiosInstance.get("/link/id/" + id);
  return result.data;
}

export async function getDomains(includePublic = false) {
  const query = {
    includePublic,
  };
  const result = await axiosInstance.get("/domains", { params: query });
  return result.data;
}

export async function createAliasUrl(domainId, path, redirectUrl) {
  const result = await axiosInstance.post("/domains/" + domainId + "/alias", {
    path: "/" + (path ? path : ""),
    redirect_url: redirectUrl,
  });
  return result.data;
}

export async function updateAliasUrl(domainId, path, redirectUrl) {
  const result = await axiosInstance.put("/domains/" + domainId + "/alias", {
    path: "/" + (path ? path : ""),
    redirect_url: redirectUrl,
  });
  return result.data;
}

export async function deleteAliasUrl(domainId, path) {
  const result = await axiosInstance.delete("/domains/" + domainId + "/alias", {
    data: { path },
  });
  return result.data;
}

export async function listApiKeys() {
  const result = await axiosInstance.get("/user/api-key");
  return result.data;
}

export async function deleteApiKey(id) {
  const result = await axiosInstance.delete("/user/api-key/" + id);
  return result.data;
}

export async function createApiKey(note) {
  const dataToUse =
    typeof note === "string" ? { description: note } : undefined;
  const result = await axiosInstance.post("/user/api-key/", dataToUse);
  return result.data;
}

/**
 * used to notify email change or display name changes
 */
export async function notifyEmailVerified() {
  const result = await axiosInstance.put("/user/email");
  return result.data;
}

export async function updateUserMetadata(data) {
  try {
    const result = await axiosInstance.put("/user/metadata", data);
    return result.data;
  } catch (err) {
    console.log(err);
    return;
  }
}

export async function refreshLandingPage(linkId) {
  const result = await axiosInstance.put(
    "/link/id/" + linkId + "/landing_page"
  );
  return result.data;
}
