export default {
  dashboard: "Dashboard",
  plan: "Plan | Plans",
  your_plan: "Your plan",
  receipt: "Receipt | Receipts",
  account: "Account",
  link: "Link | Links",
  terms: "Terms",
  privacy: "Privacy",
  support: "Support",
  upgrade: "Upgrade",
  downgrade: "Downgrade",
  limit: "Limit | Limits",
  price: "Price",
  available_plans: "Available plans",
  login: "Login",
  signup: "Signup",
  language: "Language",
  loading: "Loading",
  resetPassword: "Reset password",
  emailPswWrong: "Email or password not valid",
  pswWrong: "Error, Entered password not valid",
  emailEqual: "Error, Entered email is equal to the current one in use",
  generalInputError:
    "Error, one or more data entered is not valid, check your input and try again",
  confirmPassword: "Confirm password",
  logout: "Logout",
};
