<template>
  <v-container>
    <v-row>
      <div>
        <div class="headline mb-4">Account</div>
        Update account details
      </div>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="12" sm="4">
        <p>Before doing any changes you need to enter your current password</p>
        <v-text-field
          v-model="currentPassword"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Current Password"
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-row>
      <p class="ml-3 font-weight-medium">Profile</p>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field v-model="displayName" label="First Name"></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field v-model="email" label="Email"></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn outlined color="green" class="mt-3" @click="updateProfileOrEmail"
          >Update</v-btn
        >
      </v-col>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-row>
      <p class="ml-3 font-weight-medium">Password</p>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="newPassword.input1"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          label="New password"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="newPassword.input2"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          name="input-10-1"
          label="Confirm your new password"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn outlined color="green" class="mt-3" @click="updatePassword"
          >Update</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  updateEmail,
  updatePassword,
  updateProfile,
  reauthenticate,
} from "../auth";

export default {
  computed: {
    ...mapGetters(["getUserEmail", "getUserName"]),
  },
  data() {
    return {
      email: null,
      displayName: null,
      show1: false,
      show2: false,
      currentPassword: null,
      newPassword: {
        input1: null,
        input2: null,
      },
    };
  },
  mounted() {
    // load values (deep copy)
    this.email = this.getUserEmail;
    this.displayName = this.getUserName;
    this.$store.commit("setIsLoading", false);
  },
  methods: {
    async reauthenticate() {
      try {
        await reauthenticate(this.currentPassword);
        return true;
      } catch (err) {
        console.log(err);
        window.alert(this.$t("pswWrong"));
        return false;
      }
    },
    async updateEmail() {
      console.log("update email");
      const success = await this.reauthenticate();
      if (success) {
        const newEmail = this.email.trim();
        return updateEmail(newEmail);
      }
    },
    async updateProfileOrEmail() {
      console.log("updateProfileOrEmail", this.email, this.displayName);
      if (this.email && this.email !== this.getUserEmail) {
        await this.updateEmail();
      }

      if (this.displayName && this.displayName !== this.getUserName) {
        await this.updateProfile();
        this.$store.commit("showSnackbarMessage", { content: "Update done" });
      }
    },
    async updateProfile() {
      console.log("updating profile");
      const success = await this.reauthenticate();
      if (success) {
        const displayName = this.displayName.trim();
        return updateProfile({ displayName });
      }
    },
    async updatePassword() {
      console.log("updating profile");
      const success = await this.reauthenticate();
      if (success) {
        if (!this.newPassword.input1 || !this.newPassword.input2) {
          window.alert(this.$t("generalInputError"));
          return false;
        }
        if (this.newPassword.input1 !== this.newPassword.input2) {
          window.alert(this.$t("generalInputError"));
          return false;
        }
        const password = this.newPassword.input2;
        return updatePassword(password);
      }
    },
  },
};
</script>
