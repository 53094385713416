import Vue from "vue";
import Router from "vue-router";
import Domains from "./views/Domains.vue";
import Account from "./views/Account.vue";
import Login from "./views/Login.vue";
import Signup from "./views/Signup.vue";
import Plan from "./views/Plan.vue";
import Links from "./views/Links.vue";
import TermsOfService from "./views/TermsOfService.vue";
import PrivacyPolicy from "./views/PrivacyPolicy.vue";
import { auth } from "./auth";
import store from "./store";
import Processing from "./views/Processing.vue";
import AlmostDone from "./views/AlmostDone.vue";
import Integrations from "./views/Integrations.vue";
import VerifyEmailAddress from "./views/VerifyEmailAddress.vue";

Vue.use(Router);

// define all internal paths->component mapping
const router = new Router({
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false,
        removeBanner: true,
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: Signup,
      meta: {
        requiresAuth: false,
        removeBanner: true,
      },
    },
    /*
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/overview",
      name: "overview",
      component: Home,
    },
    */
    {
      path: "/processing",
      name: "processing",
      component: Processing,
    },
    {
      path: "/domains",
      name: "domains",
      component: Domains,
    },
    {
      path: "/integrations",
      name: "integrations",
      component: Integrations,
    },
    {
      path: "/account",
      name: "account",
      component: Account,
    },
    {
      path: "/plan",
      name: "plan",
      component: Plan,
    },
    {
      path: "/links",
      name: "links",
      component: Links,
    },
    {
      path: "/almost-done",
      name: "almost-done",
      component: AlmostDone,
    },
    {
      path: "/verify-email",
      name: "verify-email",
      component: VerifyEmailAddress,
    },
    {
      path: "/terms-of-service",
      name: "terms-of-service",
      component: TermsOfService,
      meta: {
        requiresAuth: false,
        removeBanner: true,
      },
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
      meta: {
        requiresAuth: false,
        removeBanner: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (store.state.isLoading === false) {
    store.commit("setIsLoading", true);
  }

  if (to.path === "/" || to.path === "/overview") {
    return next("/links");
  }

  const currentUser = auth().currentUser;

  const requiresAuth = to.matched.some((record) => {
    if (record.meta && typeof record.meta.requiresAuth !== "undefined") {
      return record.meta.requiresAuth;
    }
    return true;
  });
  const isAuthPath = to.name === "login" || to.name === "signup";

  console.log("path requires auth", requiresAuth);
  console.log("is auth path", isAuthPath);

  if (requiresAuth && !currentUser && !isAuthPath) {
    console.log("redirecting to login");
    const headedPath = window.location.hash.substr(1);
    const query =
      headedPath && headedPath !== "/links" ? { from: headedPath } : null;
    return next({ name: "login", query });
  }

  console.log("currentUser", currentUser);

  if (isAuthPath && currentUser) {
    return next("/links");
  }

  if (currentUser && (to.path === "/" || to.path === "/overview")) {
    return next("/links");
  }

  return next();
});

export default router;
