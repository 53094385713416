<template>
  <div>
    <div class="text-center">
      <div>
        <v-img
          :src="require('../assets/almost_done.svg')"
          class="my-3 mt-8"
          contain
          height="200"
          alt="almost done"
        ></v-img>
      </div>

      <h1 class="grey--text text--darken-2 mt-10">We are almost there</h1>
      <p class="grey--text text--darken-1 text-subtitle">
        Your email is now confirmed, please answer the questions below, this
        allows us to provide a better service to you. Thanks
      </p>

      <v-container fluid>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="6" sm="4" md="3" xl="2">
            <v-subheader> Company size </v-subheader>
          </v-col>

          <v-col cols="6" sm="4" md="3" xl="2">
            <v-select
              v-model="companySize"
              :items="['1-20', '21-50', '51-100', '101-200', '200+']"
              label="Select"
              persistent-hint
              single-line
            ></v-select>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="6" sm="4" md="3" xl="2">
            <v-subheader> Company main sector </v-subheader>
          </v-col>

          <v-col cols="6" sm="4" md="3" xl="2">
            <v-select
              v-model="companyMainSector"
              :items="[
                'IT / Software',
                'Marketing',
                'Finance',
                'Healthcare',
                'Construction',
                'Food Services',
                'Accommodation',
                'Retail Trade',
                'Other',
              ]"
              label="Select"
              persistent-hint
              single-line
            ></v-select>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" no-gutters>
          <v-col cols="6" sm="4" md="3" xl="2">
            <v-subheader>Where do you saw 1LINK.IO ?</v-subheader>
          </v-col>

          <v-col cols="6" sm="4" md="3" xl="2">
            <v-select
              v-model="attributionSource"
              :items="[
                'Product Hunt',
                'Capterra',
                'Reddit',
                'SaaSHub',
                'Conference / Event',
                'AlternativeTo.net',
                'Word of mouth',
                'Google / Search',
                'Other',
              ]"
              label="Select"
              persistent-hint
              single-line
            ></v-select>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" xl="2">
            <v-btn
              depressed
              color="green"
              class="white--text"
              block
              @click="clickUpdateMetadata"
            >
              Submit
            </v-btn>

            <v-btn text small class="mt-4" to="/links">Skip</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { notifyEmailVerified, updateUserMetadata } from "../api";
export default {
  data() {
    return {
      companyMainSector: null,
      companySize: null,
      attributionSource: null,
    };
  },
  mounted() {
    Promise.all([this.emailVerified()]).finally(async () => {
      this.$store.commit("setIsLoading", false);
    });
  },
  methods: {
    async emailVerified() {
      // call endpoint to notify that email was verified
      const _res = await notifyEmailVerified();
      return _res;
    },
    async clickUpdateMetadata() {
      this.$store.commit("setIsLoading", true);
      // since this is optionally silently throw
      try {
        const data = {
          company: {
            mainSector: this.companyMainSector,
            size: this.companySize,
          },
          attribution: {
            userSourceSelected: this.attributionSource,
          },
        };
        console.log("sending", data);
        const _res = await updateUserMetadata(data);
      } catch (err) {
        console.error(err);
      }

      this.$router.push("/links");
    },
  },
};
</script>
