const firebase = {
  apiKey: "AIzaSyAAqRA43z_SPjNjz14EIiezor8IdAKSmMk",
  authDomain: "link-98c5d.firebaseapp.com",
  databaseURL: "https://link-98c5d.firebaseio.com",
  projectId: "link-98c5d",
  storageBucket: "link-98c5d.appspot.com",
  messagingSenderId: "867051291871",
};

export default firebase;
