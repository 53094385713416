const config = {
  APP_NAME: "1LINK.IO",
  LOGO_URL: "https://cdn.1link.io/assets/images/pr-kit/color/full_logo.png",
  SUPPORT_EMAIL: "info@1link.io",
  COMPANY_NAME: "143 Software",
  COMPANY_ADDRESS: "Amsterdam, Netherlands",
  APP_START_YEAR: "2017",
  WEBSITE_URL: "https://1link.io",
  APP_URL: "https://app.1link.io",
  API_BASE_URL: "https://api.1link.io/v1",
  LANGUAGE_SELECT: [
    { text: "English", value: "en" },
    { text: "عَرَبيْ", value: "ar" },
    { text: "Italiano", value: "it" },
  ],
};

export default config;
