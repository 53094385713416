<template>
  <v-snackbar v-model="show" :color="color">
    {{ message }}
    <v-btn text @click="show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "",
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      console.log(mutation.type, state);
      if (mutation.type === "showSnackbarMessage") {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color ? state.snackbar.color : "success";
        this.show = true;
      }
    });
  },
};
</script>
