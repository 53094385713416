import config from "./config";
import * as firebase from "firebase/app";

import "firebase/auth";

const app = firebase.initializeApp(config);

app.auth().useDeviceLanguage();

export function auth() {
  return app.auth();
}

export function signup(email, password) {
  return auth().createUserWithEmailAndPassword(email, password);
}

/**
 * must be called only for resends
 */
export function sendEmailVerification() {
  const user = auth().currentUser;
  return user.sendEmailVerification({
    url: "https://app.1link.io/#/almost-done",
  });
}

export function loginViaGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  return auth().signInWithPopup(provider);
}

export function signupViaGoogle() {
  return loginViaGoogle();
}

export function getJwtToken() {
  return auth().currentUser.getIdToken();
}

export function login(email, password) {
  return auth().signInWithEmailAndPassword(email, password);
}

export function logout() {
  return auth().signOut();
}

export function updatePassword(newPassword) {
  const user = firebase.auth().currentUser;
  return user.updatePassword(newPassword);
}

export function updateEmail(newEmail) {
  const user = firebase.auth().currentUser;
  return user.updateEmail(newEmail);
}

export function updateProfile(data) {
  const user = firebase.auth().currentUser;
  /*
  data = {
    displayName: "Jane Q. User",
    photoURL: "https://example.com/jane-q-user/profile.jpg"
  }
  */
  return user.updateProfile(data);
}

/**
 * authenticate before any updates
 */
export function reauthenticate(currentPassw) {
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    currentPassw
  );
  return user.reauthenticateWithCredential(credential);
}
